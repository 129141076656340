var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sign-in", {
        attrs: { visible: _vm.isSignIn, "is-to-cart": _vm.isToCart },
        on: {
          "is-sign-in-active": function($event) {
            _vm.isSignIn = false
          },
          checkout: _vm.navigateToCart
        }
      }),
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "progress-circle",
            attrs: { size: 70, width: 7, color: "purple", indeterminate: "" }
          })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "header second-header second-header-outer" },
            [
              _c(
                "v-container",
                [
                  _c("div", { staticClass: "slick-position" }, [
                    _c(
                      "div",
                      { staticClass: "border-top " },
                      [
                        _vm.categories.length
                          ? _c(
                              "VueSlickCarousel",
                              {
                                staticClass: "category-slick menu-sections",
                                attrs: {
                                  infinite: false,
                                  draggable: false,
                                  "slides-to-show": _vm.sliderLimit,
                                  "touch-move": false,
                                  responsive: _vm.sliderResponsive
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prevArrow",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "custom-arrow" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    fill: "none",
                                                    height: "40",
                                                    viewBox: "0 0 15 15",
                                                    width: "40",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "clip-rule": "evenodd",
                                                      d:
                                                        "M1 7.5C1 7.66148 1.07798 7.81301 1.20938 7.90687L8.20938 12.9069C8.36179 13.0157 8.56226 13.0303 8.72879 12.9446C8.89533 12.8589 9 12.6873 9 12.5L9 10L11.5 10C11.7761 10 12 9.77614 12 9.5L12 5.5C12 5.22386 11.7761 5 11.5 5L9 5L9 2.5C9 2.31271 8.89533 2.14112 8.72879 2.05542C8.56226 1.96972 8.36179 1.98427 8.20938 2.09313L1.20938 7.09314C1.07798 7.18699 1 7.33853 1 7.5ZM8 3.4716L8 5.5C8 5.77614 8.22386 6 8.5 6L11 6L11 9L8.5 9C8.22386 9 8 9.22386 8 9.5L8 11.5284L2.36023 7.5L8 3.4716Z",
                                                      fill: "#fff",
                                                      "fill-rule": "evenodd"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "nextArrow",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "custom-arrow" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    fill: "none",
                                                    height: "40",
                                                    viewBox: "0 0 15 15",
                                                    width: "40",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "clip-rule": "evenodd",
                                                      d:
                                                        "M14 7.5C14 7.66148 13.922 7.81301 13.7906 7.90687L6.79062 12.9069C6.63821 13.0157 6.43774 13.0303 6.27121 12.9446C6.10467 12.8589 6 12.6873 6 12.5L6 10L3.5 10C3.22386 10 3 9.77614 3 9.5L3 5.5C3 5.22386 3.22386 5 3.5 5L6 5L6 2.5C6 2.31271 6.10467 2.14112 6.27121 2.05542C6.43774 1.96972 6.63821 1.98427 6.79062 2.09313L13.7906 7.09314C13.922 7.18699 14 7.33853 14 7.5ZM7 3.4716L7 5.5C7 5.77614 6.77614 6 6.5 6L4 6L4 9L6.5 9C6.77614 9 7 9.22386 7 9.5L7 11.5284L12.6398 7.5L7 3.4716Z",
                                                      fill: "#fff",
                                                      "fill-rule": "evenodd"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2252388241
                                )
                              },
                              _vm._l(_vm.categories, function(category) {
                                return _c(
                                  "div",
                                  {
                                    key: category.id,
                                    staticClass:
                                      "m-2 item-categories font-weight-bold",
                                    class: [
                                      category.id == _vm.activeCategory
                                        ? "active"
                                        : ""
                                    ],
                                    on: {
                                      click: function($event) {
                                        _vm.activeCategory = category.id
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v(_vm._s(category.name))])]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "menu-item-list" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-4",
                          attrs: { md: "4", cols: "12", "order-md": "2" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-card",
                                { staticClass: "home-info-card mb-3" },
                                [
                                  _c("orderInfo", {
                                    ref: "orderInfoRef",
                                    attrs: {
                                      "is-adrress": false,
                                      "order-info-details": _vm.orderData
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "my_order" },
                                [
                                  _c("myOrder", {
                                    attrs: { "checkout-button": true },
                                    on: {
                                      "delete-item": _vm.deleteItemEmit,
                                      "update-item": _vm.productUpdate,
                                      "customize-countUpdate": _vm.qtyUpdate,
                                      "update-customizedItem":
                                        _vm.updateCustomizedItem,
                                      checkout: _vm.navigateToCart,
                                      "show-login": _vm.showLogin
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 mt-4 ",
                          attrs: { md: "8", cols: "12", "order-md": "1" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.grouped, function(item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.id,
                                  staticClass: "pb-0 pe-md-0",
                                  attrs: { sm: "12", md: "6" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "mv-2 itemsCard" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "cursor-pointer detail-txt-wrap",
                                              attrs: { sm: "8" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.spin(
                                                    item,
                                                    item.qty + 1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              item.name.length > 40
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { right: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tooltip"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-title",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "itemName d-inline-block text-truncate mb-0 pb-0",
                                                                          staticStyle: {
                                                                            "max-width":
                                                                              "350px"
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.name
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      item.name.length > 40
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "itemName d-inline-block text-truncate mb-0 pb-0"
                                                            },
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "itemName d-inline-block text-no-wrap mb-0 pb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.name) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                              _c("v-card-text", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "align-items-left item-list"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "amount d-flex pt-2"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "amount-wrap"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " €" +
                                                                _vm._s(
                                                                  item.menu_modifiers &&
                                                                    item.price ==
                                                                      0
                                                                    ? "From " +
                                                                        _vm
                                                                          .lowestModPrice(
                                                                            item.menu_modifiers
                                                                          )
                                                                          .min.price.toFixed(
                                                                            2
                                                                          ) +
                                                                        " to " +
                                                                        _vm
                                                                          .lowestModPrice(
                                                                            item.menu_modifiers
                                                                          )
                                                                          .max.pricetoFixed(
                                                                            2
                                                                          )
                                                                    : item.price.toFixed(
                                                                        2
                                                                      )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "sep"
                                                          },
                                                          [_vm._v("⎮")]
                                                        ),
                                                        _c("v-img", {
                                                          staticClass:
                                                            "information",
                                                          attrs: {
                                                            src: require("@/assets/images/icons/info.svg")
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.itemDetail(
                                                                item,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "description text-ellipsis--3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.description
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: {
                                                cols: "4",
                                                sm: "4",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pro-img-outer"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "product-img"
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        staticClass:
                                                          "rounded-lg item-img cursor-pointer",
                                                        attrs: {
                                                          src: item.image_url,
                                                          alt: "Image"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.imgItemDetail(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 cart-minus"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "right-align-buttons"
                                                        },
                                                        [
                                                          item.qty != 0
                                                            ? _c(
                                                                "plus-minus-field",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      item.qty,
                                                                    type: item.menu_modifiers
                                                                      ? "customize"
                                                                      : undefined
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.spin(
                                                                        item,
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          item.qty == 0
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "add-to-cart-btn",
                                                                  attrs: {
                                                                    elevation:
                                                                      "0",
                                                                    small: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addToCart(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [_vm._v("Add")]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      item.menu_modifiers
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "customize-btn",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "primary",
                                                                plain: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.customizeOrder(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Customize "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "fixed-footer mob-fixed-footer" },
                [
                  _c("v-container", [
                    _vm.$store.state["cart"].cartItems.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "bg-gradient-secondary footer",
                            class: { "highlight-div": _vm.ordersDialog }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "7" } }, [
                                  _c("div", { staticClass: "ttl-txt-outer" }, [
                                    _c(
                                      "div",
                                      { staticClass: "order-dialog" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.ordersDialog = !_vm.ordersDialog
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "Icon icon-theme item-drop"
                                              },
                                              [_vm._v(" mdi-menu-down ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "ttl-txt" }, [
                                      _c(
                                        "div",
                                        { staticClass: "subtotal-wrap" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "total-items" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.totalQty) +
                                                  " items "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "total-amount" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totalAmount.toFixed(2)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "from-txt" }, [
                                        _vm._v(
                                          " From: " +
                                            _vm._s(
                                              _vm.$store.state.outlet.outletInfo
                                                .name
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  ])
                                ]),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "5", align: "right" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "footer-btn theme-btn",
                                        on: { click: _vm.navigateToCart }
                                      },
                                      [_vm._v(" PROCEED ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ordersDialog
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.closeDialog,
                                expression: "closeDialog"
                              }
                            ],
                            staticStyle: {
                              top: "auto",
                              height: "auto",
                              bottom: "0px",
                              "z-index": "180"
                            },
                            attrs: { role: "document", tabindex: "0" }
                          },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "bottom-sheet-transition" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-dialog v-dialog--active v-bottom-sheet v-bottom-sheet--inset",
                                    staticStyle: {
                                      "max-width": "70%",
                                      margin: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-sheet",
                                      { staticClass: "sheet-modal" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "my-3 mb-15" },
                                          _vm._l(
                                            _vm.$store.state["cart"].cartItems,
                                            function(item, index) {
                                              return _c(
                                                "v-row",
                                                { key: item.id + index },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "order-box",
                                                          attrs: {
                                                            elevation: "0"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "7",
                                                                        md: "6"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "order-names"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "h3",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "order-row"
                                                                            },
                                                                            [
                                                                              _vm._l(
                                                                                item.menu_modifiers,
                                                                                function(
                                                                                  modifierGroup
                                                                                ) {
                                                                                  return _c(
                                                                                    "span",
                                                                                    {
                                                                                      key:
                                                                                        modifierGroup.id,
                                                                                      staticClass:
                                                                                        "modifier-group"
                                                                                    },
                                                                                    [
                                                                                      modifierGroup
                                                                                        .selected_modifiers
                                                                                        .length >
                                                                                      0
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex justify-center modifier-group-items"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "small",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      modifierGroup.name
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._l(
                                                                                                modifierGroup.selected_modifiers,
                                                                                                function(
                                                                                                  modifier
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      key:
                                                                                                        modifier.id
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "small",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                modifier.name
                                                                                                              ) +
                                                                                                              ","
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        : _vm._e()
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              item.menu_modifiers &&
                                                                              item
                                                                                .menu_modifiers
                                                                                .length >
                                                                                0
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        variant:
                                                                                          "link",
                                                                                        color:
                                                                                          "primary",
                                                                                        plain:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.customizeOrderUpdate(
                                                                                            item,
                                                                                            index
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Customize "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ],
                                                                            2
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "5",
                                                                        md: "6"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "price-detail tex-right"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "h2",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    (
                                                                                      item.price *
                                                                                      item.qty
                                                                                    ).toFixed(
                                                                                      2
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "plus-minus-field",
                                                                            {
                                                                              attrs: {
                                                                                value:
                                                                                  item.qty
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.spin(
                                                                                    item,
                                                                                    $event,
                                                                                    index,
                                                                                    "update"
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          ref: "custom",
          attrs: { id: "customize", width: "500", "overlay-opacity": "0.8" },
          model: {
            value: _vm.customizeDialog,
            callback: function($$v) {
              _vm.customizeDialog = $$v
            },
            expression: "customizeDialog"
          }
        },
        [
          _vm.activeItem
            ? _c(
                "v-container",
                { staticClass: "grey lighten-5 pt-0 pb-0 customise-popup" },
                [
                  _c("h2", { staticClass: "text-center pop-hd" }, [
                    _vm._v(" CUSTOMIZE ")
                  ]),
                  _c("div", { staticClass: "customise-header sticky" }, [
                    _c("h4", [_vm._v(_vm._s(_vm.activeItem.name))]),
                    _c("h5", [_vm._v(_vm._s(_vm.menuItemPrice))])
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-5 customise-heading" },
                    _vm._l(_vm.activeItem.menu_modifiers, function(
                      option,
                      index
                    ) {
                      return _c(
                        "v-col",
                        {
                          key: option.id,
                          staticClass: "mt-2 cutomize-pop-col",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c("h2", [
                            _vm._v(
                              " " +
                                _vm._s(option.name) +
                                " (select min " +
                                _vm._s(option.min) +
                                " and max " +
                                _vm._s(option.max) +
                                ") "
                            )
                          ]),
                          option.min != 0 &&
                          option.min - option.selected_modifiers.length != 0 &&
                          option.selected_modifiers.length !== option.max
                            ? _c("h4", { staticClass: "red--text pt-1" }, [
                                _vm._v(
                                  " Choose min " +
                                    _vm._s(
                                      option.min -
                                        option.selected_modifiers.length
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm._l(option.modifiers, function(modifier) {
                            return _c(
                              "div",
                              {
                                key: modifier.name,
                                staticClass: "checkbox-div"
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    value: modifier,
                                    disabled:
                                      option.selected_modifiers.length >=
                                        option.max &&
                                      _vm.getItemIndex(index, modifier.id) ==
                                        -1,
                                    label: modifier.price
                                      ? modifier.name +
                                        "(€ " +
                                        modifier.price +
                                        ")"
                                      : modifier.name
                                  },
                                  model: {
                                    value: option.selected_modifiers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        option,
                                        "selected_modifiers",
                                        $$v
                                      )
                                    },
                                    expression: "option.selected_modifiers"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-footer footer-sticky" },
                    [
                      _c("plus-minus-field", {
                        attrs: { value: _vm.activeItem.qty, type: undefined },
                        on: {
                          change: function($event) {
                            _vm.activeItem.qty = $event
                          }
                        }
                      }),
                      !_vm.isUpdate
                        ? _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.selectedModifiersQty },
                              on: {
                                click: function($event) {
                                  return _vm.addToCart(_vm.activeItem)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " ADD TO ORDER - " +
                                  _vm._s(
                                    (
                                      _vm.activeItem.qty *
                                      (parseFloat(_vm.activeItem.price) +
                                        _vm.activeItemModifierPrice())
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isUpdate
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.updateCart(
                                    _vm.activeItem,
                                    "customize"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " UPDATE ORDER - " +
                                  _vm._s(
                                    (
                                      _vm.activeItem.qty *
                                      (parseFloat(_vm.activeItem.price) +
                                        _vm.activeItemModifierPrice())
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "customiseQty",
          attrs: { id: "customizeQtyMinus", width: "500" },
          model: {
            value: _vm.customizeQtyMinusDialog,
            callback: function($$v) {
              _vm.customizeQtyMinusDialog = $$v
            },
            expression: "customizeQtyMinusDialog"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "grey lighten-5 pt-0 pb-0 customise-popup" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center removeModdalHead pa-0" },
                [
                  _c("h2", { staticClass: "text-center pop-hd" }, [
                    _vm._v(" Remove your items ")
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "closeIcon",
                      on: {
                        click: function($event) {
                          return _vm.closeRemove()
                        }
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  )
                ],
                1
              ),
              _c("v-row", { staticClass: "mt-3 customise-heading" }),
              _vm._l(_vm.customItemUpdate, function(item, index) {
                return _c(
                  "v-row",
                  { key: item.uuid },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "itemSection pr-0 pb-0 ml-2",
                        attrs: { cols: "9" }
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "pa-0 mb-0 customiseQtyHeader" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.customItemUpdate[index].name) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "pl-0 ml-4", attrs: { lg: "8" } },
                              _vm._l(item.menu_modifiers, function(
                                modifierGroup
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: modifierGroup.id,
                                    staticClass: "modifier-group ml-1"
                                  },
                                  [
                                    _c("div", { staticClass: "testDiv" }, [
                                      modifierGroup.selected_modifiers.length >
                                      0
                                        ? _c("small", [
                                            _vm._v(
                                              _vm._s(modifierGroup.name) + ":"
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    modifierGroup.selected_modifiers.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "pb-1 modifierDiv ml-2"
                                          },
                                          _vm._l(
                                            modifierGroup.selected_modifiers,
                                            function(modifier) {
                                              return _c(
                                                "small",
                                                {
                                                  key: modifier.id,
                                                  staticClass: "modifierName"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(modifier.name) +
                                                      ","
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "pb-1 modifierDiv ml-2"
                                          },
                                          _vm._l(
                                            modifierGroup.selected_modifiers,
                                            function(modifier) {
                                              return _c(
                                                "small",
                                                {
                                                  key: modifier.id,
                                                  staticClass: "modifierName"
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(modifier.name)
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                  ]
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "orderQty", attrs: { cols: "2" } },
                      [
                        _c(
                          "div",
                          { staticClass: "order-detail" },
                          [
                            _c("plus-minus-field", {
                              attrs: { value: item.qty },
                              on: {
                                change: function($event) {
                                  return _vm.spin(item, $event, index, "update")
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "modal-footer footer-sticky" })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "itemDetails",
          attrs: { id: "item-detail", width: "768", "overlay-opacity": "0.8" },
          model: {
            value: _vm.itemDetailsDialog,
            callback: function($$v) {
              _vm.itemDetailsDialog = $$v
            },
            expression: "itemDetailsDialog"
          }
        },
        [
          _vm.activeItemDetail
            ? _c(
                "v-container",
                { staticClass: "grey lighten-5 pt-0 pb-0 customise-popup" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "pt-0" }, [
                        _c("div", { staticClass: "itemDetailSys" }, [
                          _c(
                            "div",
                            { staticClass: "infoHead" },
                            [
                              _c(
                                "h3",
                                { staticClass: "itemDetailHead mb-2 mt-6" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.activeItemDetail.name) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "closeInfo",
                                  attrs: { large: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeInfo()
                                    }
                                  }
                                },
                                [_vm._v(" mdi-close ")]
                              )
                            ],
                            1
                          ),
                          _c("span", { staticClass: "itemDetail" }, [
                            _vm._v(
                              " €" +
                                _vm._s(
                                  _vm.activeItemDetail.menu_modifiers &&
                                    _vm.activeItemDetail.price == 0
                                    ? "From " +
                                        _vm
                                          .lowestModPrice(
                                            _vm.activeItemDetail.menu_modifiers
                                          )
                                          .min.price.toFixed(2) +
                                        " to " +
                                        _vm
                                          .lowestModPrice(
                                            _vm.activeItemDetail.menu_modifiers
                                          )
                                          .max.pricetoFixed(2)
                                    : _vm.activeItemDetail.price.toFixed(2)
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "d-flex mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "itemDetailDescription " },
                              [
                                _c(
                                  "div",
                                  { staticClass: "detail-description" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.activeItemDetail.description
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "line_segment3" }),
                                _c(
                                  "div",
                                  [
                                    _c("h4", { staticClass: "itemDetail" }, [
                                      _vm._v(" Information ")
                                    ]),
                                    _vm.activeItemDetail.foodtype ||
                                    _vm.activeItemDetail.chillies_count ||
                                    (_vm.activeItemDetail.allergen_items &&
                                      _vm.activeItemDetail.allergen_items
                                        .length >= 1)
                                      ? _c(
                                          "v-col",
                                          { staticClass: "pl-0 ml-0" },
                                          [
                                            _c("span", [
                                              _c(
                                                "ul",
                                                { staticClass: "pl-4" },
                                                [
                                                  _vm.activeItemDetail.foodType
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "lbl-txt"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Food Type : "
                                                              )
                                                            ]
                                                          ),
                                                          _c("div", {
                                                            staticClass:
                                                              "d-flex"
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.activeItemDetail
                                                    .chillies_count
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "lbl-txt"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Hot / Chilli : "
                                                              )
                                                            ]
                                                          ),
                                                          _vm.activeItemDetail
                                                            .chillies_count
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex"
                                                                },
                                                                _vm._l(
                                                                  _vm
                                                                    .activeItemDetail
                                                                    .chillies_count,
                                                                  function(
                                                                    option,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "v-img",
                                                                      {
                                                                        key:
                                                                          "chilli" +
                                                                          i,
                                                                        attrs: {
                                                                          "max-height":
                                                                            "15",
                                                                          "max-width":
                                                                            "15",
                                                                          src: require("@/assets/images/allergens/Chilli.svg")
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.activeItemDetail
                                                    .allergen_items &&
                                                  _vm.activeItemDetail
                                                    .allergen_items.length >= 1
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "lbl-txt"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Allergens : "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex"
                                                            },
                                                            _vm._l(
                                                              _vm
                                                                .activeItemDetail
                                                                .allergen_items,
                                                              function(
                                                                option,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "v-img",
                                                                  {
                                                                    key:
                                                                      "img" + i,
                                                                    attrs: {
                                                                      "max-height":
                                                                        "150",
                                                                      "max-width":
                                                                        "20",
                                                                      src: option.icon
                                                                        ? require("@/assets/images/allergens/" +
                                                                            option.icon +
                                                                            ".svg")
                                                                        : ""
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _c("v-col", [
                                          _c("span", [
                                            _vm._v("No Information Available")
                                          ])
                                        ])
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-3" },
                              [
                                _c("v-img", {
                                  staticClass:
                                    "rounded-lg item-img cursor-pointer",
                                  attrs: {
                                    src: _vm.activeItemDetail.image_url,
                                    height: "315px",
                                    width: "320px",
                                    alt: "Image"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemDetailMob" }, [
                          _c(
                            "div",
                            { staticClass: "infoHead" },
                            [
                              _c(
                                "h3",
                                { staticClass: "itemDetailHead mb-2 mt-6" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.activeItemDetail.name) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "closeInfo",
                                  attrs: { large: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeInfo()
                                    }
                                  }
                                },
                                [_vm._v(" mdi-close ")]
                              )
                            ],
                            1
                          ),
                          _c("span", { staticClass: "itemDetail" }, [
                            _vm._v(
                              " €" +
                                _vm._s(
                                  _vm.activeItemDetail.menu_modifiers &&
                                    _vm.activeItemDetail.price == 0
                                    ? "From " +
                                        _vm
                                          .lowestModPrice(
                                            _vm.activeItemDetail.menu_modifiers
                                          )
                                          .min.price.toFixed(2) +
                                        " to " +
                                        _vm
                                          .lowestModPrice(
                                            _vm.activeItemDetail.menu_modifiers
                                          )
                                          .max.price.toFixed(2)
                                    : _vm.activeItemDetail.price.toFixed(2)
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            {},
                            [
                              _c("v-img", {
                                staticClass:
                                  "rounded-0 item-img ml-0 cursor-pointer",
                                attrs: {
                                  src: _vm.activeItemDetail.image_url,
                                  height: "auto",
                                  width: "auto",
                                  alt: "Image"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "itemDetailDescription " },
                              [
                                _c(
                                  "div",
                                  { staticClass: "detail-description" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.activeItemDetail.description
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "line_segment3" }),
                                _c(
                                  "div",
                                  [
                                    _c("h4", { staticClass: "itemDetail" }, [
                                      _vm._v(" Information ")
                                    ]),
                                    _vm.activeItemDetail.foodtype ||
                                    _vm.activeItemDetail.chillies_count ||
                                    (_vm.activeItemDetail.allergen_items &&
                                      _vm.activeItemDetail.allergen_items
                                        .length >= 1)
                                      ? _c(
                                          "v-col",
                                          { staticClass: "pl-0 ml-0" },
                                          [
                                            _c("span", [
                                              _c(
                                                "ul",
                                                { staticClass: "pl-4" },
                                                [
                                                  _vm.activeItemDetail.foodType
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Food Type : "
                                                          ),
                                                          _c("div", {
                                                            staticClass:
                                                              "d-flex"
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.activeItemDetail
                                                    .chillies_count
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Hot / Chilli : "
                                                          ),
                                                          _vm.activeItemDetail
                                                            .chillies_count
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex"
                                                                },
                                                                _vm._l(
                                                                  _vm
                                                                    .activeItemDetail
                                                                    .chillies_count,
                                                                  function(
                                                                    option,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "v-img",
                                                                      {
                                                                        key:
                                                                          "chilli" +
                                                                          i,
                                                                        attrs: {
                                                                          "max-height":
                                                                            "180",
                                                                          "max-width":
                                                                            "18",
                                                                          src: require("@/assets/images/allergens/Chilli.svg")
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.activeItemDetail
                                                    .allergen_items &&
                                                  _vm.activeItemDetail
                                                    .allergen_items.length >= 1
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Allergens : "
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex"
                                                            },
                                                            _vm._l(
                                                              _vm
                                                                .activeItemDetail
                                                                .allergen_items,
                                                              function(
                                                                option,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "v-img",
                                                                  {
                                                                    key:
                                                                      "img" + i,
                                                                    attrs: {
                                                                      "max-height":
                                                                        "150",
                                                                      "max-width":
                                                                        "20",
                                                                      src: option.icon
                                                                        ? require("@/assets/images/allergens/" +
                                                                            option.icon +
                                                                            ".svg")
                                                                        : ""
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _c("v-col", [
                                          _c("span", [
                                            _vm._v("No Information Available")
                                          ])
                                        ])
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "modal-footer footer-sticky" })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 0 },
          model: {
            value: _vm.minOrdersnackbar,
            callback: function($$v) {
              _vm.minOrdersnackbar = $$v
            },
            expression: "minOrdersnackbar"
          }
        },
        [
          _vm._v(
            " Min Order Value is " +
              _vm._s(parseFloat(_vm.minOrderValue).toFixed(2)) +
              " "
          ),
          _c(
            "v-btn",
            {
              staticClass: "snackbar-close",
              attrs: { color: "pink", variant: "text" },
              on: {
                click: function($event) {
                  _vm.minOrdersnackbar = false
                }
              }
            },
            [
              _c("v-icon", { staticClass: "icon-close", attrs: { dark: "" } }, [
                _vm._v(" mdi-close ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }