<template>
  <div>
    <!--Sign IN -->
    <sign-in
      :visible="isSignIn"
      :is-to-cart="isToCart"
      @is-sign-in-active="isSignIn = false"
      @checkout="navigateToCart"
    />

    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="purple"
      indeterminate
      class="progress-circle"
    />

    <div
      v-if="!loading"
      class="header second-header second-header-outer"
    >
      <v-container>
        <!-- <v-radio-group :value="selectedItem">
          <v-radio
            v-for="item in items"
            :key="item.id"
            :label="item.label"
            :value="item"
          />
        </v-radio-group> -->
        <div class="slick-position">
          <div class="border-top ">
            <VueSlickCarousel
              v-if="categories.length"
              :infinite="false"
              :draggable="false"
              :slides-to-show="sliderLimit"
              :touch-move="false"
              :responsive="sliderResponsive"
              class="category-slick menu-sections"
            >
              <template #prevArrow="">
                <div class="custom-arrow">
                  <svg
                    fill="none"
                    height="40"
                    viewBox="0 0 15 15"
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M1 7.5C1 7.66148 1.07798 7.81301 1.20938 7.90687L8.20938 12.9069C8.36179 13.0157 8.56226 13.0303 8.72879 12.9446C8.89533 12.8589 9 12.6873 9 12.5L9 10L11.5 10C11.7761 10 12 9.77614 12 9.5L12 5.5C12 5.22386 11.7761 5 11.5 5L9 5L9 2.5C9 2.31271 8.89533 2.14112 8.72879 2.05542C8.56226 1.96972 8.36179 1.98427 8.20938 2.09313L1.20938 7.09314C1.07798 7.18699 1 7.33853 1 7.5ZM8 3.4716L8 5.5C8 5.77614 8.22386 6 8.5 6L11 6L11 9L8.5 9C8.22386 9 8 9.22386 8 9.5L8 11.5284L2.36023 7.5L8 3.4716Z"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </svg>
                </div>
              </template>

              <template #nextArrow="">
                <div class="custom-arrow">
                  <svg
                    fill="none"
                    height="40"
                    viewBox="0 0 15 15"
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M14 7.5C14 7.66148 13.922 7.81301 13.7906 7.90687L6.79062 12.9069C6.63821 13.0157 6.43774 13.0303 6.27121 12.9446C6.10467 12.8589 6 12.6873 6 12.5L6 10L3.5 10C3.22386 10 3 9.77614 3 9.5L3 5.5C3 5.22386 3.22386 5 3.5 5L6 5L6 2.5C6 2.31271 6.10467 2.14112 6.27121 2.05542C6.43774 1.96972 6.63821 1.98427 6.79062 2.09313L13.7906 7.09314C13.922 7.18699 14 7.33853 14 7.5ZM7 3.4716L7 5.5C7 5.77614 6.77614 6 6.5 6L4 6L4 9L6.5 9C6.77614 9 7 9.22386 7 9.5L7 11.5284L12.6398 7.5L7 3.4716Z"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </svg>
                </div>
              </template>
              <div
                v-for="category in categories"
                :key="category.id"
                class="m-2 item-categories font-weight-bold"
                :class="[category.id == activeCategory ? 'active' : '']"
                @click="activeCategory = category.id"
              >
                <span>{{ category.name }}</span>
              </div>
            </VueSlickCarousel>
          </div>
        </div>

        <!------------------------ Item card section ----------------------->
        <!-- <div class="ordersInfo">
          <v-row>
            <v-col
              sm="12"
              class="mb-0 pb-0 mt-2"
            >
              <orderInfo
                ref="orderInfoRef"
                :is-adrress="false"
                :order-data="orderData"
              />
            </v-col>
          </v-row>
        </div> -->

        <v-row class="menu-item-list">
          <v-col
            md="4"
            cols="12"
            class="mt-4"
            order-md="2"
          >
            <div>
              <!-------------------------- Order Timing Section ------------------------->
              <v-card class="home-info-card mb-3">
                <orderInfo
                  ref="orderInfoRef"
                  :is-adrress="false"
                  :order-info-details="orderData"
                />
              </v-card>
              <!----------------------------My Order Section ------------------------>
              <div class="my_order">
                <myOrder
                  :checkout-button="true"
                  @delete-item="deleteItemEmit"
                  @update-item="productUpdate"
                  @customize-countUpdate="qtyUpdate"
                  @update-customizedItem="updateCustomizedItem"
                  @checkout="navigateToCart"
                  @show-login="showLogin"
                />
              </div>
            </div>
          </v-col>
          <v-col
            class="pa-0 mt-4 "
            md="8"
            cols="12"
            order-md="1"
          >
            <div class="d-flex flex-wrap">
              <v-col
                v-for="item in grouped"
                :key="item.id"
                sm="12"
                md="6"
                class="pb-0 pe-md-0"
              >
                <v-card class="mv-2 itemsCard">
                  <v-row no-gutters>
                    <v-col
                      sm="8"
                      class="cursor-pointer detail-txt-wrap"
                      @click="spin(item, item.qty + 1)"
                    >
                      <v-tooltip
                        v-if="item.name.length > 40"
                        right
                      >
                        <template #activator="{ on }">
                          <div class="tooltip">
                            <v-card-title
                              class="itemName d-inline-block text-truncate mb-0 pb-0"
                              style="max-width: 350px;"
                              v-on="on"
                            >
                              {{ item.name }}
                            </v-card-title>
                          </div>
                        </template>
                        <span
                          v-if="item.name.length > 40"
                          class="itemName d-inline-block text-truncate mb-0 pb-0"
                        >
                          <h3>{{ item.name }}</h3>
                        </span>
                      </v-tooltip>
                      <v-card-title
                        v-else
                        class="itemName d-inline-block text-no-wrap mb-0 pb-0"
                      >
                        {{ item.name }}
                      </v-card-title>
                      <v-card-text>
                        <div class="align-items-left item-list">
                          <div class="amount d-flex pt-2">
                            <span class="amount-wrap">
                              €{{
                                item.menu_modifiers && item.price == 0
                                  ? 'From ' +
                                    lowestModPrice(
                                      item.menu_modifiers,
                                    ).min.price.toFixed(2) +
                                    ' to ' +
                                    lowestModPrice(
                                      item.menu_modifiers,
                                    ).max.pricetoFixed(2)
                                  : item.price.toFixed(2)
                              }}
                            </span>
                            <span class="sep">⎮</span>
                            <v-img
                              src="@/assets/images/icons/info.svg"
                              class="information"
                              @click="itemDetail(item, $event)"
                            />
                          </div>

                          <div class="description text-ellipsis--3">
                            {{ item.description }}
                          </div>
                        </div>
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                      class="text-right"
                    >
                      <div class="pro-img-outer">
                        <div class="product-img">
                          <v-img
                            :src="item.image_url"
                            alt="Image"
                            class="rounded-lg item-img cursor-pointer"
                            @click="imgItemDetail(item)"
                          />
                        </div>

                        <div class="w-100 cart-minus">
                          <div class="right-align-buttons">
                            <plus-minus-field
                              v-if="item.qty != 0"
                              :value="item.qty"
                              :type="
                                item.menu_modifiers ? 'customize' : undefined
                              "
                              @change="spin(item, $event)"
                            />
                            <v-btn
                              v-if="item.qty == 0"
                              elevation="0"
                              small
                              class="add-to-cart-btn"
                              @click="addToCart(item)"
                            >Add</v-btn>
                          </div>
                          <v-btn
                            v-if="item.menu_modifiers"
                            small
                            color="primary"
                            plain
                            class="customize-btn"
                            @click="customizeOrder(item)"
                          >
                            Customize
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </div>
          </v-col>

          <!----------------------------------- Right Col Section --------------------------->
        </v-row>
      </v-container>
      <div class="fixed-footer mob-fixed-footer">
        <v-container>
          <div
            v-if="$store.state['cart'].cartItems.length > 0"
            class="bg-gradient-secondary footer"
            :class="{ 'highlight-div': ordersDialog }"
          >
            <v-row>
              <v-col cols="7">
                <div class="ttl-txt-outer">
                  <div class="order-dialog">
                    <v-btn @click="ordersDialog = !ordersDialog">
                      <v-icon class="Icon icon-theme item-drop">
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ttl-txt">
                    <div class="subtotal-wrap">
                      <div class="total-items">
                        {{ totalQty }} items
                      </div>
                      <div class="total-amount">
                        {{ totalAmount.toFixed(2) }}
                      </div>
                    </div>
                    <div class="from-txt">
                      From: {{ $store.state.outlet.outletInfo.name }}
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col
                cols="5"
                align="right"
              >
                <v-btn
                  class="footer-btn theme-btn"
                  @click="navigateToCart"
                >
                  PROCEED
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div
            v-if="ordersDialog"
            v-click-outside="closeDialog"
            role="document"
            class=""
            style="top:auto;height:auto;bottom:0px;z-index:180;"
            tabindex="0"
          >
            <transition name="bottom-sheet-transition">
              <div
                class="v-dialog v-dialog--active v-bottom-sheet v-bottom-sheet--inset"
                style="max-width:70%;margin:0"
              >
                <!-- content goes here -->

                <v-sheet class="sheet-modal">
                  <div class="my-3 mb-15">
                    <v-row
                      v-for="(item, index) in $store.state['cart'].cartItems"
                      :key="item.id + index"
                    >
                      <v-col cols="12">
                        <v-card
                          elevation="0"
                          class="order-box"
                        >
                          <v-card-text>
                            <v-row align="center">
                              <v-col
                                cols="7"
                                md="6"
                              >
                                <div class="order-names">
                                  <h3>{{ item.name }}</h3>
                                  <div class="order-row">
                                    <span
                                      v-for="modifierGroup in item.menu_modifiers"
                                      :key="modifierGroup.id"
                                      class="modifier-group"
                                    >
                                      <span
                                        v-if="
                                          modifierGroup.selected_modifiers
                                            .length > 0
                                        "
                                        class="d-flex justify-center modifier-group-items"
                                      >
                                        <small>{{ modifierGroup.name }}:</small>
                                        <span
                                          v-for="modifier in modifierGroup.selected_modifiers"
                                          :key="modifier.id"
                                        >
                                          <small> {{ modifier.name }},</small>
                                        </span>
                                      </span>
                                    </span>
                                    <v-btn
                                      v-if="
                                        item.menu_modifiers &&
                                          item.menu_modifiers.length > 0
                                      "
                                      variant="link"
                                      color="primary"
                                      plain
                                      @click="customizeOrderUpdate(item, index)"
                                    >
                                      Customize
                                    </v-btn>
                                  </div>
                                </div>
                              </v-col>
                              <v-col
                                cols="5"
                                md="6"
                              >
                                <div class="price-detail tex-right">
                                  <h2>
                                    {{ (item.price * item.qty).toFixed(2) }}
                                  </h2>
                                  <plus-minus-field
                                    :value="item.qty"
                                    @change="spin(item, $event, index, 'update')"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-sheet>

              </div>
            </transition>
          </div>

        </v-container>
      </div>
    </div>
    <!--
    <v-dialog
      id="modal-1"
      v-model="ordersDialog"
      title="Your Orders"
      size="xl"
      transition="dialog-bottom-transition"
      width="1185"
    /> -->

    <!-- ==================================Dialog Box================================== -->

    <v-dialog
      id="customize"
      ref="custom"
      v-model="customizeDialog"
      width="500"
      overlay-opacity="0.8"
    >
      <v-container
        v-if="activeItem"
        class="grey lighten-5 pt-0 pb-0 customise-popup"
      >
        <h2 class="text-center pop-hd">
          CUSTOMIZE
        </h2>
        <div class="customise-header sticky">
          <h4>{{ activeItem.name }}</h4>
          <h5>{{ menuItemPrice }}</h5>
          <!-- <h5>{{ (activeItem.qty * (activeItem.price + activeItemModifierPrice())).toFixed(2) }}</h5> -->
        </div>
        <v-row class="mt-5 customise-heading">
          <v-col
            v-for="(option, index) in activeItem.menu_modifiers"
            :key="option.id"
            cols="12"
            md="12"
            class="mt-2 cutomize-pop-col"
          >
            <!-- <v-form-checkbox v-model="activeItem.selected_groups" :value="option"> -->
            <h2>
              {{ option.name }} (select min {{ option.min }} and max
              {{ option.max }})
            </h2>
            <!-- </v-form-checkbox> -->
            <h4
              v-if="
                option.min != 0 &&
                  option.min - option.selected_modifiers.length != 0 &&
                  option.selected_modifiers.length !== option.max
              "
              class="red--text pt-1"
            >
              Choose min {{ option.min - option.selected_modifiers.length }}
            </h4>
            <!-- {{ option.selected_modifiers[0] }} -->

            <div
              v-for="modifier in option.modifiers"
              :key="modifier.name"
              class="checkbox-div"
            >
              <v-checkbox
                v-model="option.selected_modifiers"
                :value="modifier"
                :disabled="
                  option.selected_modifiers.length >= option.max &&
                    getItemIndex(index, modifier.id) == -1
                "
                :label="
                  modifier.price
                    ? `${modifier.name}(€ ${modifier.price})`
                    : modifier.name
                "
              />
            </div>
          </v-col>
        </v-row>
        <div class="modal-footer footer-sticky">
          <plus-minus-field
            :value="activeItem.qty"
            :type="undefined"
            @change="activeItem.qty = $event"
          />
          <!-- {{totalModifiersQty}} {{selectedModifiersQty}} -->
          <v-btn
            v-if="!isUpdate"
            :disabled="selectedModifiersQty"
            @click="addToCart(activeItem)"
          >
            ADD TO ORDER -
            {{
              (
                activeItem.qty *
                (parseFloat(activeItem.price) + activeItemModifierPrice())
              ).toFixed(2)
            }}
          </v-btn>

          <v-btn
            v-if="isUpdate"
            @click="updateCart(activeItem, 'customize')"
          >
            UPDATE ORDER -
            {{
              (
                activeItem.qty *
                (parseFloat(activeItem.price) + activeItemModifierPrice())
              ).toFixed(2)
            }}
          </v-btn>
        </div>
      </v-container>
    </v-dialog>

    <v-dialog
      id="customizeQtyMinus"
      ref="customiseQty"
      v-model="customizeQtyMinusDialog"
      width="500"
    >
      <v-container class="grey lighten-5 pt-0 pb-0 customise-popup">
        <div class="d-flex justify-center removeModdalHead pa-0">
          <h2 class="text-center pop-hd">
            Remove your items
          </h2>
          <v-icon
            class="closeIcon"
            @click="closeRemove()"
          >
            mdi-close
          </v-icon>
        </div>
        <v-row class="mt-3 customise-heading" />
        <v-row
          v-for="(item, index) in customItemUpdate"
          :key="item.uuid"
        >
          <v-col
            cols="9"
            class="itemSection pr-0 pb-0 ml-2"
          >
            <p class="pa-0 mb-0 customiseQtyHeader">
              {{ customItemUpdate[index].name }}
            </p>

            <v-row>
              <v-col
                lg="8"
                class="pl-0 ml-4"
              >
                <div
                  v-for="modifierGroup in item.menu_modifiers"
                  :key="modifierGroup.id"
                  class="modifier-group ml-1"
                >
                  <div class="testDiv">
                    <small
                      v-if="modifierGroup.selected_modifiers.length > 0"
                    >{{ modifierGroup.name }}:</small>
                  </div>
                  <div
                    v-if="modifierGroup.selected_modifiers.length > 1"
                    class="pb-1 modifierDiv ml-2"
                  >
                    <small
                      v-for="modifier in modifierGroup.selected_modifiers"
                      :key="modifier.id"
                      class="modifierName"
                    >
                      {{ modifier.name }},</small>
                  </div>
                  <div
                    v-else
                    class="pb-1 modifierDiv ml-2"
                  >
                    <small
                      v-for="modifier in modifierGroup.selected_modifiers"
                      :key="modifier.id"
                      class="modifierName"
                    >
                      {{ modifier.name }}</small>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="2"
            class="orderQty"
          >
            <div class="order-detail">
              <plus-minus-field
                :value="item.qty"
                @change="spin(item, $event, index, 'update')"
              />
            </div>
          </v-col>
        </v-row>
        <div class="modal-footer footer-sticky" />
      </v-container>
    </v-dialog>

    <v-dialog
      id="item-detail"
      ref="itemDetails"
      v-model="itemDetailsDialog"
      width="768"
      overlay-opacity="0.8"
    >
      <v-container
        v-if="activeItemDetail"
        class="grey lighten-5 pt-0 pb-0 customise-popup"
      >
        <v-row>
          <v-col class="pt-0">
            <div class="itemDetailSys">
              <div class="infoHead">
                <h3 class="itemDetailHead mb-2 mt-6">
                  {{ activeItemDetail.name }}
                </h3>
                <v-icon
                  class="closeInfo"
                  large
                  @click="closeInfo()"
                >
                  mdi-close
                </v-icon>
              </div>
              <span class="itemDetail">
                €{{
                  activeItemDetail.menu_modifiers && activeItemDetail.price == 0
                    ? 'From ' +
                      lowestModPrice(
                        activeItemDetail.menu_modifiers,
                      ).min.price.toFixed(2) +
                      ' to ' +
                      lowestModPrice(
                        activeItemDetail.menu_modifiers,
                      ).max.pricetoFixed(2)
                    : activeItemDetail.price.toFixed(2)
                }}
              </span>
              <div class="d-flex mt-2">
                <div class="itemDetailDescription ">
                  <div class="detail-description">
                    {{ activeItemDetail.description }}
                  </div>
                  <div class="line_segment3" />

                  <div>
                    <h4 class="itemDetail">
                      Information
                    </h4>
                    <v-col
                      v-if="
                        activeItemDetail.foodtype ||
                          activeItemDetail.chillies_count ||
                          (activeItemDetail.allergen_items &&
                            activeItemDetail.allergen_items.length >= 1)
                      "
                      class="pl-0 ml-0"
                    >
                      <span>
                        <ul class="pl-4">
                          <li
                            v-if="activeItemDetail.foodType"
                            class="d-flex"
                          >
                            <div class="lbl-txt">
                              Food Type :
                            </div>

                            <div class="d-flex" />
                          </li>
                          <li
                            v-if="activeItemDetail.chillies_count"
                            class="d-flex"
                          >
                            <div class="lbl-txt">
                              Hot / Chilli :
                            </div>
                            <div
                              v-if="activeItemDetail.chillies_count"
                              class="d-flex"
                            >
                              <v-img
                                v-for="(option,
                                        i) in activeItemDetail.chillies_count"
                                :key="'chilli' + i"
                                max-height="15"
                                max-width="15"
                                :src="
                                  require('@/assets/images/allergens/Chilli.svg')
                                "
                              />
                            </div>
                          </li>
                          <li
                            v-if="
                              activeItemDetail.allergen_items &&
                                activeItemDetail.allergen_items.length >= 1
                            "
                            class="d-flex"
                          >
                            <div class="lbl-txt">
                              Allergens :
                            </div>
                            <div class="d-flex">
                              <v-img
                                v-for="(option,
                                        i) in activeItemDetail.allergen_items"
                                :key="'img' + i"
                                max-height="150"
                                max-width="20"
                                :src="
                                  option.icon
                                    ? require('@/assets/images/allergens/' +
                                      option.icon +
                                      '.svg')
                                    : ''
                                "
                              />
                            </div>
                          </li>
                        </ul>
                      </span>
                    </v-col>
                    <v-col v-else>
                      <span>No Information Available</span>
                    </v-col>
                  </div>
                </div>
                <div class="ml-3">
                  <v-img
                    :src="activeItemDetail.image_url"
                    height="315px"
                    width="320px"
                    alt="Image"
                    class="rounded-lg item-img cursor-pointer"
                  />
                </div>
              </div>
            </div>

            <!-- item detail mob -->
            <div class="itemDetailMob">
              <div class="infoHead">
                <h3 class="itemDetailHead mb-2 mt-6">
                  {{ activeItemDetail.name }}
                </h3>
                <v-icon
                  class="closeInfo"
                  large
                  @click="closeInfo()"
                >
                  mdi-close
                </v-icon>
              </div>
              <span class="itemDetail">
                €{{
                  activeItemDetail.menu_modifiers && activeItemDetail.price == 0
                    ? 'From ' +
                      lowestModPrice(
                        activeItemDetail.menu_modifiers,
                      ).min.price.toFixed(2) +
                      ' to ' +
                      lowestModPrice(
                        activeItemDetail.menu_modifiers,
                      ).max.price.toFixed(2)
                    : activeItemDetail.price.toFixed(2)
                }}
              </span>
              <div class="">
                <v-img
                  :src="activeItemDetail.image_url"
                  height="auto"
                  width="auto"
                  alt="Image"
                  class="rounded-0 item-img ml-0 cursor-pointer"
                />
              </div>
              <div class="d-flex mt-2">
                <div class="itemDetailDescription ">
                  <div class="detail-description">
                    {{ activeItemDetail.description }}
                  </div>

                  <div class="line_segment3" />

                  <div>
                    <h4 class="itemDetail">
                      Information
                    </h4>
                    <v-col
                      v-if="
                        activeItemDetail.foodtype ||
                          activeItemDetail.chillies_count ||
                          (activeItemDetail.allergen_items &&
                            activeItemDetail.allergen_items.length >= 1)
                      "
                      class="pl-0 ml-0"
                    >
                      <span>
                        <ul class="pl-4">
                          <li
                            v-if="activeItemDetail.foodType"
                            class="d-flex"
                          >
                            Food Type :
                            <div class="d-flex" />
                          </li>
                          <li
                            v-if="activeItemDetail.chillies_count"
                            class="d-flex"
                          >
                            Hot / Chilli :
                            <div
                              v-if="activeItemDetail.chillies_count"
                              class="d-flex"
                            >
                              <v-img
                                v-for="(option,
                                        i) in activeItemDetail.chillies_count"
                                :key="'chilli' + i"
                                max-height="180"
                                max-width="18"
                                :src="
                                  require('@/assets/images/allergens/Chilli.svg')
                                "
                              />
                            </div>
                          </li>
                          <li
                            v-if="
                              activeItemDetail.allergen_items &&
                                activeItemDetail.allergen_items.length >= 1
                            "
                            class="d-flex"
                          >
                            Allergens :
                            <div class="d-flex">
                              <v-img
                                v-for="(option,
                                        i) in activeItemDetail.allergen_items"
                                :key="'img' + i"
                                max-height="150"
                                max-width="20"
                                :src="
                                  option.icon
                                    ? require('@/assets/images/allergens/' +
                                      option.icon +
                                      '.svg')
                                    : ''
                                "
                              />
                            </div>
                          </li>
                        </ul>
                      </span>
                    </v-col>
                    <v-col v-else>
                      <span>No Information Available</span>
                    </v-col>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="modal-footer footer-sticky" />
      </v-container>
    </v-dialog>
    <v-snackbar
      v-model="minOrdersnackbar"
      :timeout="0"
    >
      Min Order Value is {{ parseFloat(minOrderValue).toFixed(2) }}

      <v-btn
        color="pink"
        variant="text"
        class="snackbar-close"
        @click="minOrdersnackbar = false"
      >
        <v-icon
          dark
          class="icon-close"
        >
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import store from '@/store'
import { ref, computed, watch } from '@vue/composition-api'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import moment from 'moment'
import { useRouter } from '../utils/utils'
import plusMinusField from '../components/plusMinusField.vue'
import SignIn from '../components/SignIn.vue'
import useSignIn from '../components/useSignIn'

import myOrder from '../components/MyOrders.vue'
import orderInfo from '../components/OrderInfo.vue'
// import Map from '../components/Map.vue'
import { userService } from '../api/userService'

export default {
  components: {
    VueSlickCarousel,
    plusMinusField,
    SignIn,
    myOrder,
    orderInfo,
  },

  setup() {
    const categories = ref([])

    // const timings = ref([])

    // const timeIntervels = ref([])

    const productList = ref([])

    // const orderTimes = ref([])

    // const orderType = ref([])

    const activeCategory = ref(null)

    const cart = ref([])

    const activeItem = ref(null)

    const activeItemDetail = ref(null)

    // const custom = ref(null)
    const isUpdate = ref(false)

    const activeCartItemIndex = ref(null)

    const ordersDialog = ref(false)

    const customizeDialog = ref(false)

    const customizeQtyMinusDialog = ref(false)

    const itemDetailsDialog = ref(false)

    const { router } = useRouter()

    const resultQuery = ref(store.state.user.menuSearchQuery)

    const isStoreDetails = ref(false)

    const deleteDialog = ref(false)

    const activeIndex = ref(null)

    const sumOfQty = ref(null)

    const customItemUpdate = ref(null)

    const outletDetails = ref(null)

    const isToCart = ref(false)

    const activeItemMinModCount = ref(0)

    const activeItemPrice = ref(0)

    const menuItemPrice = ref(0)

    const loading = ref(true)

    const sliderLimit = ref(10)

    const orderInfoRef = ref(null)

    const orderData = ref({})
    orderData.value = store.state.user.orderDetails || {}

    const minOrdersnackbar = ref(false)

    const storeSettings = store.state.outlet.outletInfo.online_store_settings.store

    const minOrderValue = ref(1)

    const sliderResponsive = ref([
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ])

    const items = [
      { id: 1, label: 'one' },
      { id: 2, label: 'two' },
      { id: 3, label: 'three' },
    ]
    const selectedItem = ref({ id: 1, label: 'one' })

    selectedItem.value = items.find(i => i.id === selectedItem.value.id)

    // onUpdated(() => {
    //   debugger
    //   console.log('updated!')
    // })

    const getIndex = item => item.findIndex(i => i.id === 2)

    userService.getStoreDetails().then(res => {
      if (res.data) {
        outletDetails.value = res.data
      }
    })

    const secondRequest = store.dispatch('cart/fetchCategories').then(res => {
      categories.value = res.data
      activeCategory.value = categories.value[0].id
      if (categories.value.length <= 10) {
        sliderLimit.value = categories.value.length
      }
    })

    const firstRequest = store.dispatch('cart/fetchItems').then(res => {
      productList.value = res.data
      const filteredContent = []
      store.state.cart.cartItems.forEach(el => {
        const record = productList.value.find(item => item.id === el.id)
        if (el.menu_modifiers) {
          if (filteredContent.find(e => e.id === el.id)) {
            const objIndex = filteredContent.findIndex(e => e.id === el.id)
            const count = filteredContent[objIndex].qty + el.qty
            filteredContent[objIndex].qty = count
            record.qty = count
          } else {
            filteredContent.push({ id: el.id, qty: el.qty })
            record.qty = el.qty
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (record) {
            record.qty = el.qty
          }
        }
      })
    })

    // userService.'poe', setOutlet(router.currentRoute.params).then(() => {
    Promise.all([firstRequest, secondRequest]).then(() => {
      loading.value = false
    })
    // })

    const getItemIndex = (groupIndex, modifierId) => {
      const index = activeItem.value.menu_modifiers[
        groupIndex
      ].selected_modifiers.findIndex(el => el.id === modifierId)
      return index
    }
    const formatval = vals => {
      const outp = moment(vals, 'hh:mm').format('HH:mm')
      return outp
    }

    const lowestModPrice = data => {
      // const priceArray = []
      // data.forEach(el => {
      // data[0].modifiers.forEach(e => {
      //   if (e.price) {
      //     priceArray.push(e.price)
      //   }
      // })
      // })
      // const min = Math.min(...priceArray)
      // return min
      const filteredModifiers = data[0].modifiers.filter(el => el.price != null)
      const min = filteredModifiers.reduce(
        (prev, curr) => (prev.price < curr.price ? prev : curr),
        0,
      )
      const max = filteredModifiers.reduce(
        (prev, curr) => (prev.price > curr.price ? prev : curr),
        0,
      )

      // if (activeItem.value && activeItem.value.price === 0) {
      //   activeItem.value.menu_modifiers[0].selected_modifiers.push(min)
      // }
      // data[0].selected_modifiers.push(min)
      const prices = { min, max }

      return prices
    }
    const setActiveItemPrice = () => {
      if (activeItem.value.price === 0 && activeItem.value.menu_modifiers) {
        activeItem.value.menu_modifiers[0].selected_modifiers.push(
          lowestModPrice(activeItem.value.menu_modifiers).min,
        )
        menuItemPrice.value = lowestModPrice(
          activeItem.value.menu_modifiers,
        ).min.price
      } else {
        menuItemPrice.value = activeItem.value.price
      }
    }

    const intervelTime = (openTime, closeTime) => {
      const x = {
        slotInterval: 15,
        openTime,
        closeTime,
      }
      // console.log(closeTime)
      if (openTime === '24hrs') {
        x.openTime = '0000'
        x.closeTime = '2400'
      }
      const startTime = moment(x.openTime, 'HH:mm:')
      const endTime = moment(x.closeTime, 'HH:mm')
      const allTimes = []

      while (startTime < endTime) {
        allTimes.push(startTime.format('HH:mm'))
        startTime.add(x.slotInterval, 'minutes')
      }
      return allTimes
    }

    const customizeItemTotalCount = item => {
      const counter = store.state.cart.cartItems.filter(i => i.id === item.id)
      if (counter.length >= 1) {
        const getSumByKey = (arr, key) => arr.reduce(
          (accumulator, current) => accumulator + Number(current[key]),
          0,
        )
        sumOfQty.value = getSumByKey(counter, 'qty')
        const totalItem = productList.value.find(i => i.id === item.id)
        totalItem.qty = sumOfQty.value
      }
    }

    const activeItemModifierPrice = () => {
      let price = 0
      if (activeItem.value && activeItem.value.menu_modifiers) {
        activeItem.value.menu_modifiers.forEach(el => {
          el.selected_modifiers.forEach(e => {
            if (e.price) {
              price += e.price
            }
          })
        })
        activeItemPrice.value = parseFloat(activeItem.value.price) + price
      }

      return parseFloat(price)
    }

    const addToCart = (item, isCustomize = null) => {
      const cartItem = JSON.parse(JSON.stringify(item))
      isUpdate.value = false
      if (item.menu_modifiers && !customizeDialog.value) {
        customizeDialog.value = true
        activeItem.value = JSON.parse(JSON.stringify(cartItem))
        activeItem.value.qty = 1
        activeItem.value.price = parseFloat(activeItem.value.price)

        setActiveItemPrice()
        // eslint-disable-next-line no-param-reassign
        isCustomize = 'customize'
      } else if (cartItem.qty === 0) {
        // eslint-disable-next-line no-param-reassign
        cartItem.qty = 1
      }
      if (isCustomize === null) {
        if (cartItem.menu_modifiers && cartItem.menu_modifiers.length > 0) {
          cartItem.price = activeItemPrice.value
        }
        store.dispatch('cart/addToCart', cartItem).then(() => {
          const record = productList.value.find(el => el.id === cartItem.id)
          record.qty = item.qty
          customizeItemTotalCount(item)

          customizeDialog.value = false
        })
      }
    }

    // const updateCart = item => {
    //   const cartItem = JSON.parse(JSON.stringify(item))
    //   cartItem.price = activeItemPrice.value
    //   store.state.cart.cartItems[activeCartItemIndex.value].qty = cartItem.qty
    //   store.state.cart.cartItems[activeCartItemIndex.value].price = cartItem.price
    //   store.state.cart.cartItems[activeCartItemIndex.value].menu_modifiers = cartItem.menu_modifiers

    //   localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
    //   const record = productList.value.find(el => el.id === cartItem.id)
    //   record.qty = item.qty
    //   isUpdate.value = false
    //   customizeDialog.value = false
    // }

    const grouped = computed(() => {
      const groups = []

      if (!resultQuery.value) {
        groups.value = productList.value.filter(
          item => item.item_group === activeCategory.value,
        )
      } else {
        groups.value = productList.value.filter(
          item => item.item_group === activeCategory.value
            && resultQuery.value
              .toLowerCase()
              .split(' ')
              .every(v => item.name.toLowerCase().includes(v)),
        )
      }
      return groups.value
    })

    const modifierChange = event => {
      activeItem.value.selected_groups[event.id] = event
    }

    const totalAmount = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.price * i.qty
      })
      return total
    })

    // watchEffect(store.state.cart, () => {
    //   debugger
    // })

    watch(store.state.user, newState => {
      resultQuery.value = newState.menuSearchQuery
    })

    const totalQty = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.qty
      })
      return total
    })

    const totalModifiersQty = computed(() => {
      let qty = 0
      if (activeItem.value) {
        activeItem.value.menu_modifiers.forEach(el => {
          if (el.min) {
            qty += el.min
          }
        })
      }

      return qty
    })

    const selectedModifiersQty = computed(() => {
      // let length = 0
      let returnStatus = false
      if (activeItem.value) {
        // eslint-disable-next-line consistent-return
        activeItem.value.menu_modifiers.forEach(el => {
          if (el.min > 0 && el.selected_modifiers.length < el.min) {
            returnStatus = true
          }
          // length += el.selected_modifiers.length
        })
      }
      return returnStatus
    })

    // const today = () => {
    //   const date = new Date()
    //   const day = date.toLocaleString('en-us', { weekday: 'long' })
    //   return day.toLowerCase()
    // }

    // const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    // const dayName = days[new Date().getDay()]

    const getallTime = store.state.outlet.outletInfo.online_business_hours

    const spin = (item, ev, index, type = undefined) => {
      const product = JSON.parse(JSON.stringify(item))
      if (item.menu_modifiers && type === undefined) {
        // -------Checking whether the click is on minus or plus--------
        if (ev === -1) {
          customizeQtyMinusDialog.value = true

          // ------index from cartItems saves as a new array key value pair(originalIndex)--------
          const firstIndex = store.state.cart.cartItems.filter(
            (instance, i) => {
              // eslint-disable-next-line no-param-reassign
              instance.originalIndex = i
              return instance
            },
          )

          // -----filtering the firstIndex array with Id to get the clicked element using ID from cartItems(firstIndex)-------
          customItemUpdate.value = firstIndex.filter(el => el.id === item.id)
        } else {
          customizeDialog.value = true
        }
        activeItem.value = product
        activeItem.value.qty = 1
        setActiveItemPrice()
      } else {
        // eslint-disable-next-line no-lonely-if
        if (item.menu_modifiers) {
          const cartItem = store.state.cart.cartItems.find(
            el => el.uuid === item.uuid,
          )
          // const cartItem = JSON.parse(JSON.stringify(store.state.cart.cartItems))
          cartItem.qty = ev
          const productQty = productList.value.find(el => el.id === item.id)

          // --------------total count------------
          const counter = store.state.cart.cartItems.filter(
            i => i.id === item.id,
          )
          if (counter.length >= 1) {
            const getSumByKey = (arr, key) => arr.reduce(
              (accumulator, current) => accumulator + Number(current[key]),
              0,
            )
            sumOfQty.value = getSumByKey(counter, 'qty')
            productQty.qty = sumOfQty.value
          } else {
            productQty.qty = 0
          }

          // --------------splicing the item when qty is 0------------
          if (ev === 0) {
            const cartList = JSON.parse(
              JSON.stringify(store.state.cart.cartItems),
            )
            const reducedList = JSON.parse(
              JSON.stringify(customItemUpdate.value),
            )
            const cartIndex = cartList.findIndex(el => el.uuid === item.uuid)
            reducedList.splice(index, 1)
            cartList.splice(cartIndex, 1)
            store.state.cart.cartItems = cartList
            customItemUpdate.value = reducedList
          }
          localStorage.setItem(
            'cart',
            JSON.stringify(store.state.cart.cartItems),
          )
          if (customItemUpdate.value.length === 0) {
            customizeQtyMinusDialog.value = false
          }
        } else {
          product.qty = ev
          const record = store.state.cart.cartItems.find(i => i.id === item.id)
          const menu = productList.value.find(el => el.id === item.id)
          if (!record && ev !== 0) {
            addToCart(product)
          } else if (ev === 0) {
            const cartIndex = store.state.cart.cartItems.findIndex(
              el => el.id === item.id,
            )
            // if (index !== -1) {
            const cartList = JSON.parse(
              JSON.stringify(store.state.cart.cartItems),
            )
            cartList.splice(cartIndex, 1)
            store.state.cart.cartItems = cartList
            // }
          }
          if (record && ev > 0) {
            record.qty = ev
          }
          menu.qty = ev
          localStorage.setItem(
            'cart',
            JSON.stringify(store.state.cart.cartItems),
          )
        }
      }
    }

    const customizeOrder = item => {
      // activeItem.value = JSON.parse(JSON.stringify(item))
      customizeItemTotalCount(item)
      activeItem.value = JSON.parse(JSON.stringify(item))
      setActiveItemPrice()
      // activeItem.value.qty = item.qty
      activeItem.value.qty = 1
      customizeDialog.value = true
    }

    const customizeOrderUpdate = (item, index) => {
      activeItem.value = JSON.parse(JSON.stringify(item))
      const menuItem = productList.value.find(el => el.id === item.id)
      activeItem.value.price = menuItem.price
      isUpdate.value = true

      activeCartItemIndex.value = index
      customizeDialog.value = true
    }

    const { isSignIn } = useSignIn()

    const navigateToCart = () => {
      if (
        store.state.user.isLoggedIn
        || !storeSettings[orderInfoRef.value.orderType].send_otp
      ) {
        const tableNo = router.currentRoute.params.table_no || ''
        minOrderValue.value = Number(
          storeSettings[orderInfoRef.value.orderType].min_order_value,
        )
        if (totalAmount.value < minOrderValue.value) {
          minOrdersnackbar.value = true
        } else {
          const orderDetails = {
            orderType: orderInfoRef.value.orderType,
            paymentMethod: orderInfoRef.value.paymentMethod,
            pickupTime: orderInfoRef.value.pickupTime,
            storeHours: orderInfoRef.value.timeIntervels,
            intervelTime: orderInfoRef.value.intervelTime,
          }
          store.state.user.orderDetails = orderDetails
          if (tableNo !== '') {
            router.push({ name: 'cart', params: { table_no: tableNo } })
          } else {
            router.push({ name: 'cart' })
          }
        }
      } else {
        isSignIn.value = true
        isToCart.value = true
      }
    }

    const deleteButton = index => {
      activeIndex.value = index
      deleteDialog.value = true
    }

    // product list quantity update to 0 when item has deleted from the cart

    const deleteItemEmit = id => {
      const productQty = productList.value.find(el => el.id === id)
      if (productList.value.find(el => el.id === id).menu_modifiers) {
        const counter = store.state.cart.cartItems.filter(i => i.id === id)
        if (counter.length >= 1) {
          const getSumByKey = (arr, key) => arr.reduce(
            (accumulator, current) => accumulator + Number(current[key]),
            0,
          )
          sumOfQty.value = getSumByKey(counter, 'qty')
          productQty.qty = sumOfQty.value
        } else {
          productQty.qty = 0
        }
      } else {
        productQty.qty = 0
      }
      localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
    }

    // onChange plus-minus Button : product list quantity update of items which doesn't have modifiers

    const productUpdate = id => {
      const menu = productList.value.find(el => el.id === id)
      const record = store.state.cart.cartItems.find(el => el.id === id)
      if (record === undefined) {
        menu.qty = 0
      } else {
        menu.qty = record.qty
      }
    }

    // onChange plus-minus Button : product list quantity update of items which have modifiers

    const updateCustomizedItem = id => {
      const menu = productList.value.find(el => el.id === id)
      const counter = store.state.cart.cartItems.filter(i => i.id === id)
      if (counter.length >= 1) {
        const getSumByKey = (arr, key) => arr.reduce(
          (accumulator, current) => accumulator + Number(current[key]),
          0,
        )
        sumOfQty.value = getSumByKey(counter, 'qty')
        menu.qty = sumOfQty.value
      } else {
        menu.qty = 0
      }
    }

    // when updating the item : product list quantity update of items which have modifiers

    const qtyUpdate = id => {
      // const record = productList.value.find(el => el.id === cartItem.id)
      const menu = productList.value.find(el => el.id === id)
      const counter = store.state.cart.cartItems.filter(i => i.id === menu.id)
      if (counter.length >= 1) {
        const getSumByKey = (arr, key) => arr.reduce(
          (accumulator, current) => accumulator + Number(current[key]),
          0,
        )
        sumOfQty.value = getSumByKey(counter, 'qty')
        menu.qty = sumOfQty.value
      }
    }

    const showLogin = () => {
      if (storeSettings[orderInfoRef.value.orderType].send_otp) {
        isSignIn.value = true
        isToCart.value = true
      } else {
        navigateToCart()
      }
    }

    const closeRemove = () => {
      customizeQtyMinusDialog.value = false
    }

    const closeInfo = () => {
      itemDetailsDialog.value = false
    }

    const itemDetail = (item, ev) => {
      ev.stopImmediatePropagation()
      activeItemDetail.value = item
      itemDetailsDialog.value = true
    }

    const imgItemDetail = item => {
      activeItemDetail.value = item
      itemDetailsDialog.value = true
    }

    return {
      customizeOrder,
      customizeOrderUpdate,
      activeItemModifierPrice,
      totalModifiersQty,
      menuItemPrice,
      activeItemMinModCount,
      selectedModifiersQty,
      activeCategory,
      getItemIndex,
      ordersDialog,
      modifierChange,
      navigateToCart,
      spin,
      customizeDialog,
      totalAmount,
      totalQty,
      categories,
      activeItem,
      activeItemDetail,
      addToCart,
      // updateCart,
      isUpdate,
      grouped,
      getIndex,
      cart,
      items,
      selectedItem,
      isSignIn,
      resultQuery,
      isStoreDetails,
      isToCart,
      lowestModPrice,
      loading,
      sliderLimit,
      sliderResponsive,
      deleteDialog,
      deleteItemEmit,
      deleteButton,
      intervelTime,
      formatval,
      // days: ['Today'],
      // today,
      // timings,
      getallTime,
      // orderTimes,
      // orderType,
      // timeIntervels,
      show: false,
      contactView: 1,
      productUpdate,
      customizeItemTotalCount,
      qtyUpdate,
      updateCustomizedItem,
      orderInfoRef,
      orderData,
      customizeQtyMinusDialog,
      customItemUpdate,
      outletDetails,
      showLogin,
      closeRemove,
      itemDetailsDialog,
      itemDetail,
      closeInfo,
      imgItemDetail,
      minOrderValue,
      minOrdersnackbar,
    }
  },

  // watch: {
  //   '$store.state.cart': {
  //     handler(after, before) {
  //       debugger
  //       // Changes detected.
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    closeDialog(e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.ordersDialog = false
      }
    },
  },
}
</script>
